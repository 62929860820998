import React from 'react';
import classNames from 'classnames';

import Cta from '../components/sections/Cta';
import ServicesSplit from '../components/sections/ServicesSplit';
import GenericSection from '../components/sections/GenericSection';
import SectionHeader from '../components/sections/partials/SectionHeader';

import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';

class Services extends React.Component {

  render() {
    const InnovationHeader = {
      title: 'Innovation'
    };

    const NAICSHeader = {
      title: 'NAICS Codes'
    };

    const tilesClasses = classNames(
      'tiles-wrap',
      'push-left'
    );
    
    return (
      <React.Fragment>
        <section className="services-bg-image"></section>
        <ServicesSplit invertMobile imageFill topDivider />
        <GenericSection topDivider>
          <SectionHeader data={NAICSHeader} className="center-content reveal-from-top" />
          <div className={tilesClasses}>

            <div className="tiles-item reveal-from-bottom">
              <div className="tiles-item-inner has-shadow">
                <div className="government-item-content">
                  <div className="government-item-services mb-40">
                    <div className="government-item-services-title h6 text-xs text-color-high mb-24">
                      Professional, Scientific, and Technical Services
                    </div>
                    <ul className="government-item-services-list list-reset text-xs mb-32">
                      <li>541511 - Custom Computer Programming Services</li>
                      <li>541512 - Computer Systems Design Services</li>
                      <li>541513 - Computer Facilities Management Services</li>
                      <li>541519 - Other Computer Related Services</li>
                      <li>541690 - Other Scientific and Technical Consulting Services</li>
                      <li>541714 - Research and Development in Biotechnology (except Nanobiotechnology)</li>
                      <li>541715 - Research and Development in the Physical, Engineering, and Life Sciences</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="tiles-item reveal-from-bottom" data-reveal-delay="200">
              <div className="tiles-item-inner has-shadow">
                <div className="government-item-content">
                  <div className="government-item-services mb-40">
                    <div className="government-item-services-title h6 text-xs text-color-high mb-24">
                      Administrative Support Services
                    </div>

                    <ul className="government-item-services-list list-reset text-xs mb-32">
                      <li>541611 - Professional and Administrative Services</li>
                      <li>541990 - All Other Professional, Scientific and Technical Services</li>
                      <li>561110 - Office Administrative and Management Services</li>
                      <li>561210 - Facilities Support Services</li>
                      <li>561311 - Employment Placement Agency</li>
                      <li>561320 - Temporary Help Services</li>
                      <li>541612 - Human Resources Consulting Services</li>
                      <li>561790 - Other Services to Building and Dwellings</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-delay="400">
              <div className="tiles-item-inner has-shadow">
                <div className="government-item-content">
                  <div className="government-item-services mb-40">
                    <div className="government-item-services-title h6 text-xs text-color-high mb-24">
                      Health Care and Social Assistance
                    </div>
                    <ul className="government-item-services-list list-reset text-xs mb-32">
                      <li>621111 - Offices of Physicians (Except Mental Health Specialists)</li>
                      <li>621210 - Offices of Dentists</li>
                      <li>621310 - Offices of Chiropractors</li>
                      <li>621320 - Offices of Optometrists</li>
                      <li>621399 - Offices of All Other Miscellaneous Health Practitioners</li>
                      <li>621340 - Offices of Physical, Occupational and Speech Therapists, and Audiologists</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>                            

          </div>
        </GenericSection>
        <GenericSection topDivider>
     
            <SectionHeader data={InnovationHeader} className="center-content reveal-from-top" />
            <Accordion className="reveal-from-top">
              <AccordionItem title="eCommerce Specialists" image={require("../assets/images/icons8-add-shopping-cart-100.png")} active>
                Our team has an unparalleled ability to pragmatically deliver a complete end-to-end digital sales platform. We are committed to utilizing the best-in-class technologies to architect, implement, and support end-to-end modern E-commerce systems with enterprise level integrations and hosting. We have expertise across Product & Inventory Management, Enterprise Integrations, Online and In-Store Systems, Search, Security, Analytics plus Promotions & Marketing. 
              </AccordionItem>
              <AccordionItem title="Employee Tools" image={require("../assets/images/icons8-administrative-tools-100.png")} >
                We built an online application for Omni Group NA that would allow the organization to streamline on-boarding of staff for client opportunities. The application was developed within two weeks and offered services such as document management, signing of government documents, and information exchange with an Oracle internal application for HR management.
              </AccordionItem>
              <AccordionItem title="ViewIT" image={require("../assets/images/icons8-bulleted-list-100.png")} >
                ViewIt is our in-house software solution for content management services. ViewIt is a SaaS scalable product that allows for the management of content in many formats such as PDF, Word, Excel, MP3, and video. The core of the application allows for imaging shops to use the application to manage document assets through OCR search, user defined indexes, and document access through user defined group privileges.
              </AccordionItem>
              <AccordionItem title="Rabbit Framework Development" image={require("../assets/images/icons8-running-rabbit-100.png")} >
                The Rabbit Framework allows us to speedily and efficiently develop custom applications at a lower cost! Our template design incorporates much of what most applications need to function such as authentication/authorization, analytics, user management, video streaming capabilities, and an Android extension.
              </AccordionItem>
            </Accordion>
       
        </GenericSection>         

        <Cta invertColor split className="has-bg-color-cut" />
      </React.Fragment>
    );
  }
}

export default Services;