import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class ServicesTiles extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'services-tiles section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'services-tiles-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'What We Do',
      paragraph: 'We provide unique and transformative solutions that enable our clients to modernize faster and enhance their services.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" />
            <div className={tilesClasses}>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap">
                <div className="tiles-item-inner">
                  <div className="services-tiles-item-header">
                    <div className="services-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/icons8-multiple-devices-100.png')}
                        alt="application modernization"
                        width={100}
                        height={100} />
                    </div>
                  </div>
                  <div className="services-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Application Modernization
                    </h4>
                    <p className="m-0 text-sm">
                      Our full-stack software engineers bring the best development practices to build flexible, secure, and scalable software with our unique tools and techniques. We develop rapidly and efficiently to aid our clients in getting their requirements quickly by reducing risk with fully automated and on-demand deployments, eliminating waste, and optimizing legacy workloads with modern architectures.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="100">
                <div className="tiles-item-inner">
                  <div className="services-tiles-item-header">
                    <div className="services-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/icons8-cloud-sync-100.png')}
                        alt="cloud and infrastructure"
                        width={100}
                        height={100} />
                    </div>
                  </div>
                  <div className="services-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Cloud & Infrastructure 
                    </h4>
                    <p className="m-0 text-sm">
                      Our certified cloud and infrastructure engineers apply a highly tailored approach to ensure that our clients receive solutions that seamlessly integrate with their workflow and mission objectives. After reviewing our clients’ entire infrastructure, current vulnerabilities, and network requirements, our team of cloud engineers implement the latest technology and develop secure infrastructure and processes.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="200">
                <div className="tiles-item-inner">
                  <div className="services-tiles-item-header">
                    <div className="services-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/icons8-biotech-100.png')}
                        alt="bioinformatics"
                        width={100}
                        height={100} />
                    </div>
                  </div>
                  <div className="services-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Bioinformatics
                    </h4>
                    <p className="m-0 text-sm">
                      We offer a broad range of bioinformatics and analytical services. Our highly qualified bioinformaticians and our network of subject matter experts allow us to remain at the cutting edge of bioinformatics and incorporate the latest innovations into the solutions that we provide, which range from big data and cloud computing to genomics and proteomics.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="300">
                <div className="tiles-item-inner">
                  <div className="services-tiles-item-header">
                    <div className="services-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/icons8-microscope-100.png')}
                        alt="lab sciences"
                        width={100}
                        height={100}
                        className='center-content' 
                      />
                    </div>
                  </div>
                  <div className="services-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Laboratory Sciences
                    </h4>
                    <p className="m-0 text-sm">
                      We provide first-class scientific staff that offer an extensive array of laboratory support services spanning multiple scientific disciplines. Our professional and scientific staff identifies the underlying science, methods, policies, and best practices that our clients can use to achieve their objectives.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="400">
                <div className="tiles-item-inner">
                  <div className="services-tiles-item-header">
                    <div className="services-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/icons8-accounting-100.png')}
                        alt="admin support"
                        width={100}
                        height={100} />
                    </div>
                  </div>
                  <div className="services-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Administrative Support
                    </h4>
                    <p className="m-0 text-sm">
                      Recognizing that a sound administration is essential to the success of every organization’s mission, we offer a full range of administrative support services. Our unparalleled commitment to meet our clients’ requirements, combined with our innovative recruiting strategies allow us to accurately place our team of professionals within your organization.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="500">
                <div className="tiles-item-inner">
                  <div className="services-tiles-item-header">
                    <div className="services-tiles-item-image mb-16">
                      <Image
                        src={require('./../../assets/images/icons8-skyscrapers-100.png')}
                        alt="services tile icon 06"
                        width={100}
                        height={100} />
                    </div>
                  </div>
                  <div className="services-tiles-item-content">
                    <h4 className="mt-0 mb-8">
                      Facilities Support
                    </h4>
                    <p className="m-0 text-sm">
                      We support various U.S. government agencies in providing comprehensive facility management support services. Our project management approach is designed to understand our client’s needs, developing the effective solutions, and executing projects with high quality assurance. 
                    </p>
                  </div>
                </div>
              </div>              

            </div>
          </div>
        </div>
      </section>
    );
  }
}

ServicesTiles.propTypes = propTypes;
ServicesTiles.defaultProps = defaultProps;

export default ServicesTiles;