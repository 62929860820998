import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import Image from '../elements/Image'

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class Video extends React.Component {


  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames (
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    return (
      <section
        className='video-section'
      >
          <div class="overlay"></div>
            <video autoPlay muted loop id="video">
                <source src={require("./../../assets/videos/home.mp4")} type="video/mp4"/>
            </video>
            <div className="container">
              <div className="text-container">
                <div className="text-container-header reveal-from-top">
                  <h1>Innovative Solutions.</h1>
                </div>
                <div className="text-container-header reveal-from-top" data-reveal-delay="400">
                  <h1>Transformative Outcomes.</h1>
                </div>
                <br/>
                <div className="text-container-header reveal-from-top" data-reveal-delay="600">
                <Image
                  src={require('../../assets/images/gca-logo.png')}
                  alt="GCA"
                  width={300}
                  height={300}
                  className="center-image"
                />
                </div>
              </div>
            </div>
      </section>
    );
  }
}

Video.propTypes = propTypes;
Video.defaultProps = defaultProps;

export default Video;