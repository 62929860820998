import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Switch from '../elements/Switch';
import Button from '../elements/Button';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types,
  governmentSwitcher: PropTypes.bool,
  governmentSlider: PropTypes.bool
}

const defaultProps = {
  ...SectionTilesProps.defaults,
  governmentSwitcher: false,
  governmentSlider: false
}

class GovernmentSection extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      governmentSwitcher,
      governmentSlider,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'government section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'government-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: 'Government',
      paragraph: 'By participating in various federal programs, we proudly serve our government clients innovate and meet their mission objectives.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content invert-color reveal-from-bottom" />           
            <div className={splitClasses}>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item" style={{color: 'white'}}>
                    8(a) Program Certification
                  </h3>
                  <p className="m-0 reveal-from-bottom" data-reveal-delay="100" data-reveal-container=".split-item">
                    As a minority owned 8(a) firm, we participate as a small disadvantaged business in the SBA's 8(a) Business Development Program. There are a number of procurement advantages that make it easy for government customers to award contracts. By using the 8(a) program, agencies are able to make timely decisions in the contract procurement process and reduce costs in the acquisition process while still holding to a high standard of required experience to support their mission.
                  </p>
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/america.jpg')}
                    alt="USA"
                    width={528}
                    height={396} />
                </div>
              </div>

              </div>
          </div>
        </div>
      </section>
    );
  }
}

GovernmentSection.propTypes = propTypes;
GovernmentSection.defaultProps = defaultProps;

export default GovernmentSection;