import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class ClientsAlternative extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'team section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'news-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Who We Serve',
      paragraph: 'We are a dynamic partner helping the Federal Government and private enterprises achieve their most ambitious objectives.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
          <div className="container" style={{ maxWidth: "1300px" }}>
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" />
            <div className={tilesClasses}>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" style={{padding: "10px"}}>
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-32">
                      <Image
                        src={require('./../../assets/images/dod.png')}
                        alt="Department of Defense"
                        width={200}
                        height={200} 
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="150" style={{ padding: "10px" }}>
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-32">
                      <Image
                        src={require('./../../assets/images/hud.png')}
                        alt="HUD"
                        width={200}
                        height={200}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="300" style={{padding: "10px"}}>
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-32">
                      <Image
                        src={require('./../../assets/images/tax.png')}
                        alt="US Tax Court"
                        width={200}
                        height={200} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="450" style={{padding: "10px"}}>
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-32">
                      <Image
                        src={require('./../../assets/images/gsa.png')}
                        alt="GSA"
                        width={200}
                        height={200} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="600" style={{padding: "10px"}}>
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-32">
                      <Image
                        src={require('./../../assets/images/dha.png')}
                        alt="DHA"
                        width={200}
                        height={200} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="750" style={{padding: "10px"}}>
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-32">
                      <Image
                        src={require('./../../assets/images/cdc.png')}
                        alt="CDC"
                        width={200}
                        height={200} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="900" style={{padding: "10px"}}>
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-32">
                      <Image
                        src={require('./../../assets/images/usda.png')}
                        alt="USDA"
                        width={200}
                        height={200} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="1050" style={{padding: "10px"}}>
                <div className="tiles-item-inner">
                  <div className="team-item-header">
                    <div className="team-item-image mb-32">
                      <Image
                        src={require('./../../assets/images/disa.png')}
                        alt="DISA"
                        width={200}
                        height={200} />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

ClientsAlternative.propTypes = propTypes;
ClientsAlternative.defaultProps = defaultProps;

export default ClientsAlternative;