import React from 'react';

import Cta from '../components/sections/Cta';
import Team from '../components/sections/Team';
import Mission from '../components/sections/Mission';
import Clients from '../components/sections/Clients';
import GenericSection from '../components/sections/GenericSection';
import SectionHeader from '../components/sections/partials/SectionHeader';

class About extends React.Component {

  render() {
    
    const companyDescriptionHeader = {
      title: 'Our Company'
    };

    return (
      <React.Fragment>
        <section className="about-bg-image"></section>

        <GenericSection topDivider>
            <SectionHeader data={companyDescriptionHeader} className="center-content reveal-from-top" />
            <p className="reveal-from-top">
              GCA Partners is a joint venture company combining the services provided by Weems Design Studio (WDS)  and Pittman Consulting Group (PCG). 
            </p>
            <p className="reveal-from-top" data-reveal-delay="200">
              WDS provides an array of services across four areas, IT modernization, Lab & Health Sciences, Bioinformatics/Advanced Analytics and Cloud migration. With over 10 years of experience  as a federal contractor, WDS has an unparalleled focus on providing the highest quality scientific staff, experienced bioinformaticians, and delivering modernized applications built in the cloud that enhance federal agency business processes. WDS is committed to supporting the missions of the agencies it  serves by creating positive and success oriented environments for its teams as  it strives to exceed its customer’s expectations.
            </p>
            <p className="reveal-from-top">
              PCG provides strategic administrative support services to its commercial, local, state and Federal government clients. Having  extensive experience, PCG assists its clients  in managing their administrative support needs, developing solutions,  and ensuring that their office environment, programs, and organization operates efficiently and effectively.
            </p>
        </GenericSection>
        <Mission topDivider/>   
        {/* <Team topDivider /> */}
        <Clients topDivider bottomDivider />
        <Cta invertColor split className="has-bg-color-cut" />
      </React.Fragment>
    );
  }
}

export default About;