import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

class ServicesSplit extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'services-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'services-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top'
    );

    const sectionHeader = {
      title: 'Core Competencies',
      paragraph: 'We offer a broad range of services to the Federal Government from application development and modernization to bioinformatics.'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content reveal-from-top" />
            <div className={splitClasses}>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Application Modernization
                  </h3>
             
                  <p className="m-0 reveal-from-bottom mb-16" data-reveal-delay="100" data-reveal-container=".split-item">
                    By implementing an Agile development process, we rapidly deliver robust products, adapt to changing requirements, achieve faster time to market, and decrease overall project risk for our clients.
                  </p>
    
                  <ul className="government-item-services-list list-reset text-xs mb-32">
                    <li className="is-checked reveal-from-bottom" >.NET, Open Source, Java</li>
                    <li className="is-checked reveal-from-bottom" data-reveal-delay="100">Enterprise Level Applications</li>
                    <li className="is-checked reveal-from-bottom" data-reveal-delay="200">Agile Project Management (PMI-PMP/ACP Certified)</li>
                    <li className="is-checked reveal-from-bottom" data-reveal-delay="300">DevSecOps</li>
                  </ul>
                 
                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/programming.jpg')}
                    alt="Application Modernization"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Cloud Infrastructure & Migration 
                  </h3>

                  <p className="m-0 reveal-from-bottom mb-16" data-reveal-delay="100" data-reveal-container=".split-item">
                    We apply a highly tailored approach to ensure that our clients receive solutions that seamlessly integrate with their mission objectives.
                  </p>

                  <ul className="government-item-services-list list-reset text-xs mb-32">
                    <li className="is-checked reveal-from-bottom" >AWS / Azure Certified</li>
                    <li className="is-checked reveal-from-bottom" data-reveal-delay="100">PaaS / IaaS Solutions</li>
                    <li className="is-checked reveal-from-bottom" data-reveal-delay="200">Redhat Partner</li>
                    <li className="is-checked reveal-from-bottom" data-reveal-delay="300">Infrastructure Assessment & Planning</li>
                    <li className="is-checked reveal-from-bottom" data-reveal-delay="400">Application Assessment & Planning</li>
                    <li className="is-checked reveal-from-bottom" data-reveal-delay="500">Migration Optimization</li>
                  </ul>

                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/cloud.jpg')}
                    alt="Lab Science"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Laboratory Sciences
                  </h3>

                  <p className="m-0 reveal-from-bottom mb-16" data-reveal-delay="100" data-reveal-container=".split-item">
                    We provide first-class scientific staff that offer an extensive array of laboratory support services spanning multiple scientific disciplines.
                  </p>

                  <ul className="government-item-services-list list-reset text-xs mb-32">
                    <li className="is-checked reveal-from-bottom" >Research and Development Support</li>
                    <li className="is-checked reveal-from-bottom" data-reveal-delay="100">Population Health, Health Policy Analysis, & Health Economics</li>
                    <li className="is-checked reveal-from-bottom" data-reveal-delay="200">Epidemiology and Molecular Epidemiology</li>
                    <li className="is-checked reveal-from-bottom" data-reveal-delay="300">Molecular Diagnostics Support</li>
                  </ul>

                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/lab-science.jpg')}
                    alt="Lab Science"
                    width={528}
                    height={396} />
                </div>
              </div>

              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Bioinformatics
                  </h3>

                  <p className="m-0 reveal-from-bottom mb-16" data-reveal-delay="100" data-reveal-container=".split-item">
                  Our highly qualified bioinformaticians and our network of subject matter experts allow us to remain at the cutting edge of bioinformatics and incorporate the latest innovations.
                  </p>

                  <ul className="government-item-services-list list-reset text-xs mb-32">
                    <li className="is-checked reveal-from-bottom" >Data Analytics and Informatics</li>
                    <li className="is-checked reveal-from-bottom" data-reveal-delay="100">Genomics, Proteomics, Transcriptomics, and Metagenomics</li>
                    <li className="is-checked reveal-from-bottom" data-reveal-delay="200">Pipeline Design and Implementation</li>
                    <li className="is-checked reveal-from-bottom" data-reveal-delay="300">Big Data, High Performance, and Cloud Computing</li>
                    <li className="is-checked reveal-from-bottom" data-reveal-delay="400">Database Management</li>
                  </ul>

                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/bioinfo.jpg')}
                    alt="Bioinfo"
                    width={528}
                    height={396} />
                </div>
              </div>


              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Administrative Support Services
                  </h3>

                  <p className="m-0 reveal-from-bottom mb-16" data-reveal-delay="100" data-reveal-container=".split-item">
                    Recognizing that a sound administration is essential to the success of every organization’s mission, we offer a full range of administrative support services.
                  </p>

                  <ul className="government-item-services-list list-reset text-xs mb-32">
                    <li className="is-checked reveal-from-bottom" >Administrative and Exective Assistance</li>
                    <li className="is-checked reveal-from-bottom" data-reveal-delay="100">Office Management and Administration</li>
                    <li className="is-checked reveal-from-bottom" data-reveal-delay="200">Special Events Coordination</li>
                    <li className="is-checked reveal-from-bottom" data-reveal-delay="300">Program Management</li>
                    <li className="is-checked reveal-from-bottom" data-reveal-delay="400">Human Resources Services</li>
                  </ul>

                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/admin.jpg')}
                    alt="Bioinfo"
                    width={528}
                    height={396} />
                </div>
              </div>


              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-bottom" data-reveal-container=".split-item">
                    Facilities Support
                  </h3>

                  <p className="m-0 reveal-from-bottom mb-16" data-reveal-delay="100" data-reveal-container=".split-item">
                    We support various U.S. government agencies in providing comprehensive facility management support services.
                  </p>

                  <ul className="government-item-services-list list-reset text-xs mb-32">
                    <li className="is-checked reveal-from-bottom" >Base Facilities Operation Services</li>
                    <li className="is-checked reveal-from-bottom" data-reveal-delay="100">Building Management</li>
                    <li className="is-checked reveal-from-bottom" data-reveal-delay="200">Vetted, Trained, Certified Building Engineers</li>
                    <li className="is-checked reveal-from-bottom" data-reveal-delay="300">Preventive Maintenance Services</li>
                    <li className="is-checked reveal-from-bottom" data-reveal-delay="400">Construction Clean-Up</li>
                  </ul>

                </div>
                <div className={
                  classNames(
                    'split-item-image center-content-mobile reveal-scale-up',
                    imageFill && 'split-item-image-fill'
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200">
                  <Image
                    className="has-shadow"
                    src={require('./../../assets/images/facilities.jpg')}
                    alt="Bioinfo"
                    width={528}
                    height={396} />
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>
    );
  }
}

ServicesSplit.propTypes = propTypes;
ServicesSplit.defaultProps = defaultProps;

export default ServicesSplit;