import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class Clients extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'clients section reveal-fade',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'clients-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const sectionHeader = {
      title: 'Our Clients'
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" />
            <ul className="list-reset">
              <li className="reveal-from-bottom">
                <Image
                  src={require('./../../assets/images/dod.png')}
                  alt="DOD"
                  width={100}
                  height={24} />
              </li>
              <li className="reveal-from-bottom" data-reveal-delay="150">
                <Image
                  src={require('./../../assets/images/hud.png')}
                  alt="HUD"
                  width={100}
                  height={24} />
              </li>
              <li className="reveal-from-bottom" data-reveal-delay="300">
                <Image
                  src={require('./../../assets/images/tax.png')}
                  alt="Tax"
                  width={100}
                  height={24} />
              </li>
              <li className="reveal-from-bottom" data-reveal-delay="450">
                <Image
                  src={require('./../../assets/images/gsa.png')}
                  alt="GSA"
                  width={100}
                  height={24} />
              </li>
              <li className="reveal-from-bottom" data-reveal-delay="600">
                <Image
                  src={require('./../../assets/images/dha.png')}
                  alt="DHA"
                  width={100}
                  height={24} />
              </li>
              <li className="reveal-from-bottom" data-reveal-delay="600">
                <Image
                  src={require('./../../assets/images/cdc.png')}
                  alt="CDC"
                  width={100}
                  height={24} />
              </li>
              <li className="reveal-from-bottom" data-reveal-delay="600">
                <Image
                  src={require('./../../assets/images/usda.png')}
                  alt="USDA"
                  width={100}
                  height={24} />
              </li>
            </ul>
          </div>
        </div>
      </section>
    );
  }
}

Clients.propTypes = propTypes;
Clients.defaultProps = defaultProps;

export default Clients;