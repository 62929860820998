import React from "react";
import { Link, Route, Switch } from "react-router-dom";

import SectionHeader from "../components/sections/partials/SectionHeader";
import GenericSection from "../components/sections/GenericSection";
import Cta from "../components/sections/Cta";
import Button from '../components/elements/Button';
class GSA extends React.Component {
  render() {
    const sectionHeader = {
      title: 'GSA 8(a) STARS III',
    };

    return (
      <React.Fragment>
        <section className="government-bg-image"></section>

        <GenericSection topDivider>
        <SectionHeader data={sectionHeader} className="center-content  reveal-from-top" />
          <div
            className="container has-shadow reveal-from-top"
            style={{ padding: "40px", borderRadius: "5px" }}
            data-reveal-delay="400"
          >
            <h3>Contract Overview</h3>
          
            <p>The 8(a) STARS III GWAC:</p>
            <ul>
              <li>Expands capabilities for emerging technologies</li>
              <li>
                Supports both outside of the continental United States (OCONUS)
                and CONUS requirements
              </li>
              <li>Features limited protestability up to $10M, and</li>
              <li>
                Offers expert guidance from the GSA team, including free scope
                review services and market research
              </li>
            </ul>

            <h3>Contract Specifics</h3>
            <p>
              <strong>Contract Type:</strong> Governmentwide Acquisition
              Contract
            </p>
            <p>
              <strong>Master Contract Number:</strong> 47QTCB22D0318
            </p>
            <p>
              <strong>Contractor DUNS:</strong> DUNS 117571820
            </p>
            <p>
              <strong>UEI Number:</strong> PHKDDNL2UJ24
            </p>
            <p>
              <strong>CAGE Code:</strong> CAGE 8N1A1
            </p>

            <p>Additional information on STARS III, including frequently asked questions can be found at <a href="https://www.gsa.gov/stars3" target="_blank" rel="noopener noreferrer">www.gsa.gov/stars3</a>.</p>


            <h3>Program Manager</h3>
            <p>
              <strong>Nichole Evans</strong> <br />
              Program Manager
              <br />
              s3@gcapartners.co
              <br />
              nichole.evans@gsapartners.co
              <br />
              (202) 503-6608
            </p>
          </div>
        </GenericSection>

        <Cta invertColor split className="has-bg-color-cut" />
      </React.Fragment>
    );
  }
}

export default GSA;
