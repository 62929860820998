import React from 'react';
import { withRouter, Switch } from 'react-router-dom';

import AppRoute from './utils/AppRoute';
import ScrollReveal from './utils/ScrollReveal';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views 
import Home from './views/Home';
import About from './views/About';
import Services from './views/Services';
import Government from './views/Government';
import SBA from './views/SBA';
import GSA from './views/GSA';


class App extends React.Component {

  componentDidMount() {
    document.body.classList.add('is-loaded')
    this.refs.scrollReveal.init();
  }

  // Route change
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.refs.scrollReveal.init();
    }
  }

  render() {
    return (
      <ScrollReveal
        ref="scrollReveal"
        children={() => (
          <Switch>
            <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
            <AppRoute exact path="/about" component={About} layout={LayoutDefault} />
            <AppRoute exact path="/services" component={Services} layout={LayoutDefault} />
            <AppRoute exact path="/government" component={Government} layout={LayoutDefault} />
            <AppRoute exact path="/government/sba" component={SBA} layout={LayoutDefault} />
            <AppRoute exact path="/government/gsa" component={GSA} layout={LayoutDefault} />
          </Switch>
        )} />
    );
  }
}

export default withRouter(props => <App {...props} />);