import React from 'react';
import Video from '../components/sections/Video';
import ServicesTiles from '../components/sections/ServicesTiles';
import ServicesTabs from '../components/sections/ServicesTabs';
import ClientsAlternative from '../components/sections/ClientsAlternative';
import GovernmentSection from '../components/sections/GovernmentSection';
import Cta from '../components/sections/Cta';

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
        <Video hasBgColor invertColor />
        <ServicesTiles />
        <ClientsAlternative topDivider />
        <GovernmentSection hasBgColor governmentSlider topDivider />
        <Cta hasBgColor invertColor split />
      </React.Fragment>
    );
  }
}

export default Home;