import React from 'react';

import SectionHeader from '../components/sections/partials/SectionHeader';
import GenericSection from '../components/sections/GenericSection';
import Cta from '../components/sections/Cta';

class SBA extends React.Component {
    render() {

        const sectionHeader = {
          title: 'SBA 8(a) Business Development Program',
        };
    
        return (
          <React.Fragment>
            <section className="government-bg-image"></section>
    
            <GenericSection topDivider>
              <SectionHeader data={sectionHeader} className="center-content reveal-from-top" />
              <div className="container has-shadow reveal-from-top" style={{padding: '40px', borderRadius: '5px'}} data-reveal-delay="400">
<h3 className="mt-0">Overview</h3>
<p>
  As a minority owned 8(a) firm, we participate as a small disadvantaged business in the SBA's 8(a) Business Development Program. There are a number of procurement advantages that make it easy for government customers to award contracts. By using the 8(a) program, agencies are able to make timely decisions in the contract procurement process and reduce costs in the acquisition process while still holding to a high standard of required experience to support their mission.
</p>
<p>
  <strong>8(a) Program Term:</strong> Expires Nov 2025
</p>
<p>
  <strong>Authorized Users:</strong> All Federal Agencies
</p>
<p>
  <strong>Ordering Process:</strong> Federal purchasers wishing to procure from GCA Partners using the Small Business Administration (SBA) 8(a) Program may do so by submitting a draft copy of the intended order or contract award to GCA Partners sponsoring SBA office in Atlanta, GA. GCA Partners will assist in the preparation of this document to insure its compliance with all applicable regulations.
</p>
<p>
  The SBA commits to approving the intended orders within three days from receipt of the draft, but usually provides same-day service. Once approved, agencies may submit their orders or contract awards directly to GCA Partners, without needing to engage in a competitive proposal process.
</p>
<h3>SBA Contact</h3>
<p>
  <strong>Jacqueline Carr</strong> <br/> 
  Business Opportunity Specialist<br/>
  8(a) Business Development<br/>
  SBA - Georgia District Office<br/>
  (404) 331-0243
</p>
</div>
            </GenericSection>   
    
            <Cta invertColor split className="has-bg-color-cut" />
          </React.Fragment>
        );
      }
}

export default SBA;