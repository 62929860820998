import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionTilesProps.types
}

const defaultProps = {
  ...SectionTilesProps.defaults
}

class Mission extends React.Component {

  render() {

    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'testimonial section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'testimonial-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    const tilesClasses = classNames(
      'tiles-wrap',
      pushLeft && 'push-left'
    );

    const sectionHeader = {
      title: 'Our Mission',
    };

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} tag="h1" className="center-content" />
            <div className={tilesClasses}>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-16">
                   <h4>First-Class Products</h4>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                        We relentlessly strive to provide better business strategies through custom software solutions, professional services, and technology expertise that businesses can understand.
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="200">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-16">
                   <h4>Exceptional Service</h4>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                        We believe that by providing exceptional customer service on our products and services, we can fully engage our clients  and deliver products that meet their needs. This will in turn allow our clients to develop better business practices that result in stronger financial outcomes. 
                    </p>
                  </div>
                </div>
              </div>

              <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap" data-reveal-delay="400">
                <div className="tiles-item-inner has-shadow">
                  <div className="testimonial-item-header mb-16">
                   <h4>Constant Engagement</h4>
                  </div>
                  <div className="testimonial-item-content">
                    <p className="text-sm mb-0">
                        We couple our exceptional customer service with educating our clients on technology trends and solutions. This allows our clients to make more informed decisions that will positively affect their business operations and growth. 
                    </p>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Mission.propTypes = propTypes;
Mission.defaultProps = defaultProps;

export default Mission;