import React from "react";
import { Link } from "react-router-dom";

import SectionHeader from "../components/sections/partials/SectionHeader";
import GenericSection from "../components/sections/GenericSection";
import Cta from "../components/sections/Cta";
import Image from "../components/elements/Image";

class Government extends React.Component {
  render() {
    const sectionHeader = {
      title: "Contract Vehicles",
    };

    return (
      <React.Fragment>
        <section className="government-bg-image"></section>

        <GenericSection topDivider>
          <SectionHeader
            data={sectionHeader}
            className="center-content reveal-from-top"
          />
          <div style={{display:'flex'}}>
          <div style={{marginRight: '14px', marginLeft: '14px'}}  className="center-content">
              <h4>
                <Link to="/government/sba">
                  SBA 8(a) Business Development Program
                </Link>
              </h4>
              <Link to="/government/sba">
                {" "}
                <Image
                  src={require("./../assets/images/sba.png")}
                  alt="DHA"
                  width={370}
                  height={100}
                />
              </Link>
            </div>
            <div style={{marginRight: '14px', marginLeft: '14px'}} className="center-content" >
              <h4>
                <Link to="/government/gsa">
                  GSA 8(a) STARS III
                </Link>
              </h4>
              <Link to="/government/gsa">
                {" "}
                <Image
                  src={require("./../assets/images/gsastars.png")}
                  alt="DHA"
                  width={500}
                  height={100}
                />
              </Link>
            </div>
          </div>
        </GenericSection>

        <Cta invertColor split className="has-bg-color-cut" />
      </React.Fragment>
    );
  }
}

export default Government;
